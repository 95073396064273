body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #f4f6f6 !important;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.nav-link {
  font-weight: 500;
}
.nav-link.active {
  color: #2b4594 !important;
}
.header {
  background-color: #fff;
}

.banner {
  background: url("./img/bannerh.jpg");
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
  background-position: center;
}

.countrySelect {
  border-radius: 15px;
  background-color: #fff;
  padding: 2em;
  margin-bottom: 3em;
}

.homeHead {
  text-shadow: 0px 0px 5px #545252;
  font-weight: 700;
  color: #fff;
  margin-bottom: 1em;
  margin-top: 1.6em;
  line-height: 1.3;
}
.footer {
  background-color: #edeaec;
  padding: 0 !important;
  margin-top: 2em;
}
.footer .row {
  padding: 2em;
}
.footer ul {
  list-style: none;
  margin: 0;
  padding: 0;
  color: #315194;
  font-size: 0.8rem;
}
.footer a {
  color: #2b4594;
  font-weight: 500;
}
.copyright {
  padding: 0.5em;
  color: #fff;
  background-color: black;
}
.infoBanner {
  background-image: url("./img/background-usps-tablet-green-af18d7c32b.png");
  background-size: cover;
  display: flex;
  justify-content: flex-end;
  padding: 2.5em;
  border-radius: 15px;
}

.infoBanner ul {
  padding-right: 14em;
  color: #fff;
  list-style: none;
  font-weight: 500;
  font-size: 18px;
}

.infoBanner li {
  padding: 6px 0;
}

.home h4 {
  font-weight: 700;
  margin-top: 1em;
  padding-bottom: 2em;
  color: #2b4594;
}
.accordion .card {
  margin-bottom: 2em;
  border-radius: 10px !important;
  border: 1px solid #00000020 !important;
}
.accordion h6 {
  margin: 0;
  font-weight: 700;
}

.accordion .btn {
  text-align: left;
  padding-left: 20px;
  font-weight: 600;
  color: #2b4594;
  outline: none;
}
.accordion .btn:focus {
  outline: none;
  box-shadow: none;
}
.accordion .card-header {
  background-color: #fff;
}

.accordion .card-body {
  padding: 5px 10px 0 20px;
}

.accordion p {
  margin: 0;
  padding-left: 20px;
}

.accordion li {
  margin-top: 1em;
  font-weight: 700;
}

.homeFaq {
  display: flex;
  margin-bottom: 20px;
  margin-right: auto;
  margin-left: auto;
}
.homeFaq div {
  margin-left: 20px;
}
.homeFaq p,
h6 {
  margin: 0 !important;
}

.listIcons {
  margin-right: 15px;
  font-size: 1.1em;
}

.aboutBanner {
  text-align: center;
  background-image: url("./img/Group-2.png");
  background-size: cover;
  min-height: 280px;
  padding: 2em;
  display: flex;
  justify-content: center;
  align-items: center;
}
.bannerh2 {
  color: #fff;
  text-shadow: 0 0 5px #202020;
  font-weight: 700;
}
.aboutChoose ul {
  list-style: none;
  padding: 0;
}
.aboutChoose li {
  margin-top: 1em;
  font-weight: 700;
  font-size: 1rem;
}
.aboutChoose h4 {
  font-weight: 700;
}
.aboutChoose span {
  font-weight: normal;
}

.aboutIcons {
  color: #33cc99;
  font-size: 1.4em;
  margin-right: 0.6em;
}
.carrierBanner {
  background: url("./img/bannerhss.jpg");
  background-size: cover;
  background-position: center;
  min-height: 280px;
  padding: 2em;
  display: flex;
  justify-content: center;
  align-items: center;
}

.countryBanner {
  background-image: url("./img/countries-bg-d91131eb14.jpg");
  background-position: center;
  min-height: 240px;
  padding: 2em;
  display: flex;
  justify-content: center;
  align-items: center;
}

.accountDetails p {
  margin: 0;
  font-weight: 700;
}
.accountDetails span {
  font-weight: normal;
}

.userIcon {
  font-size: 3.5em;
  text-align: center;
  width: 100%;
  margin-bottom: 5px;
  color: #aaa;
}

.orders div {
  background-color: cornflowerblue;
  padding: 10px;
  margin-top: 20px;
}
.orders p {
  border: 1px solid #6868d8;
  padding: 5px;
  margin: 0;
  text-align: center;
  font-weight: 600;
  color: #fff;
}
.reButton {
  background-color: #cb74af !important;
  border: #cb74af !important;
}
.homePay div {
  display: flex;
  justify-content: center;
}

.pCard img {
  width: 100%;
}
.pCard {
  height: 120px;
  justify-content: center;
}
.MainCard {
  justify-content: center;
}
.MainCard p {
  font-size: 11px;
  margin-bottom: 0;
}
.MainCard span {
  font-weight: 700;
  color: #e646b3;
}
.cCard {
  justify-content: center;
}
.cCard img {
  width: auto;
}

.signIcon {
  align-self: center;
  font-size: 100px;
  color: #315194;
}
.banner .dropdown-menu {
  max-height: 150px;
  overflow-y: scroll;
  width: 100%;
}
.pSign {
  font-size: 12px;
  padding: 5px;
  background-color: aquamarine;
  border: 1px solid grey;
}
.form-label {
  color: #2b4594;
  margin-bottom: 5px !important;
  font-weight: 500;
}

h3,
h4,
h5,
h6,
h1,
h2 {
  color: #2b4594;
  font-weight: 700 !important;
}

.react-tel-input .form-control {
  width: 100% !important;
}
.Desc p {
  font-size: 12px;
  font-weight: 500;
}
.Desc span {
  font-weight: 700 !important;
  margin-right: 5px;
}
.creditData {
  justify-content: center;
  border-color: #e646b3 !important;
}
.creditData .nav-link {
  color: #2b4594;
}

.creditData .nav-link.active {
  color: #fff !important;
  border-color: #cb74af !important;
  background-color: #cb74af !important;
}
.total td {
  font-weight: 700;
  font-size: 24px;
}
.receive td {
  font-weight: 700;
  font-size: 18px;
}
.reWarn {
  color: tomato;
  font-size: 12px;
}

@media screen and (max-width: 540px) {
  .homeHead {
    font-size: 1.5rem !important;
    margin-top: 10px;
  }
  .homePay {
    padding: 0 !important;
    margin-bottom: 0 !important;
  }
  .home h4 {
    font-size: 1rem;
    padding-bottom: 1em !important;
    text-align: center;
    margin-top: 2em !important;
  }
  .accordion h6 {
    font-size: 13px;
  }
  .accordion .card-body,
  p {
    font-size: 12px !important;
  }

  .accordion .btn {
    font-size: 13px !important;
  }
  .home .form-label {
    font-size: 14px;
  }
  .infoBanner ul {
    padding: 0 !important;
  }
  .infoBanner {
    border-radius: 0 !important;
    padding: 1.8em;
  }
  .homePay div {
    margin-bottom: 10px;
  }
}

@media screen and (max-width: 380px) {
  .homePay img {
    height: 24px;
    width: auto;
  }
  .accountDetails h1 {
    font-size: 24px;
    text-align: center;
  }
  .accountDetails h4 {
    font-size: 14px;
    text-align: center;
  }
  .accountDetails .userIcon {
    font-size: 3em;
  }
  .accountDetails .reButton {
    font-size: 12px;
  }
  .banner .form-text {
    margin-top: 0;
  }
}

.banner .form-text {
  font-size: 10px;
  color: #e646b3 !important;
}
.bPhone {
  background: none !important;
  border: none !important;
  text-decoration: underline;
  color: #cb74af !important;
  padding: 0 !important;
}
.deleteButton {
  color: #cc2929 !important;
  border-color: #ff4646 !important;
  background: none !important;
  font-size: 11px !important;
}
.errorh {
  font-size: 120px;
  font-weight: 800 !important;
  background: linear-gradient(273deg, #e646b3 30%, #315194 70%);
  background-clip: text;
  -webkit-text-fill-color: transparent;
}
.spiColor {
  color: #cb74af !important;
}
.react-tel-input .flag-dropdown{
  display: none !important;
}
.react-tel-input .form-control{
  padding-left: 12px !important;
}
.roundBtn{
  border-radius:100px !important;
  padding: 0.1rem .6rem !important;
  align-self: end;
}